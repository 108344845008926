import ReclaimButtons from 'components/reclaims/ReclaimButtons'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { MaxWidth, Divider, Button } from '@advanza/ui'
import Icon from '@advanza/ui/Icon'
import Page from 'components/ui/Page'
import { useSelector } from 'react-redux'
import { useReclaimsList } from 'hooks/reclaimHooks'
import ReclaimTaskOverview from 'components/reclaims/ReclaimTaskOverview'

const useQuery = () => new URLSearchParams(useLocation().search)

const ReclaimTaskPage: React.FC = () => {
    const query = useQuery()
    const filterId = query.get('filterId') || 'all'
    const { countries } = useSelector((state: any) => state.countries)
    const { currentPage, changeFilter, entities } = useReclaimsList(filterId, {
        lastXMonths: 1,
        countries,
    })
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        changeFilter({ countries })
    }, [countries])

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft') goPrev()
            if (e.key === 'ArrowRight') goNext()
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [currentIndex, currentPage])

    const hasPrev = currentIndex > 0
    const hasNext = currentIndex < currentPage.length - 1

    const goPrev = () => {
        if (hasPrev) setCurrentIndex(currentIndex - 1)
    }

    const goNext = () => {
        if (hasNext) setCurrentIndex(currentIndex + 1)
    }

    const currentReclaimId = currentPage[currentIndex]
    const currentReclaim = entities?.reclaims?.[currentReclaimId]

    return (
        <Page title="Reclaim Task" current={null} hideTop={false}>
            <MaxWidth>
                <Divider m />
                <div
                    className="sticky top-0 z-20 pt-[10px] pb-[10px] border-b border-grey-200"
                    style={{ backgroundColor: '#fafafa' }}>
                    <div className="flex items-center justify-between mb-4">
                        <Button
                            onClick={goPrev}
                            disabled={!hasPrev}
                            className={!hasPrev ? 'opacity-50 cursor-not-allowed' : ''}>
                            <Icon name="chevron_left" fontSize={28} classNameIcon="!mr-0" />
                        </Button>
                        <h1 className="text-xl font-semibold">
                            {currentIndex + 1} of {currentPage.length}
                        </h1>
                        <Button
                            onClick={goNext}
                            disabled={!hasNext}
                            className={!hasNext ? 'opacity-50 cursor-not-allowed' : ''}>
                            <Icon name={'chevron_right'} fontSize={28} classNameIcon="!mr-0" />
                        </Button>
                    </div>
                    <Divider />
                    <div className="flex items-center justify-between w-full gap-4">
                        <div className="flex-1 min-w-0">
                            <h2 className="text-lg font-bold mb-4">
                                Reclaim ID: {currentReclaimId}
                            </h2>
                        </div>

                        <div className="flex-1 text-center min-w-0 relative -top-1">
                            {currentReclaim && (
                                <ReclaimButtons
                                    key={currentReclaimId}
                                    reclaim={currentReclaim}
                                    size={'lg'}
                                    callback={goNext}
                                />
                            )}
                        </div>

                        <div className="flex-1 text-right min-w-0">
                            {/* Optional future right-side content */}
                        </div>
                    </div>
                </div>
                <Divider m />
                {currentPage.length > 0 ? (
                    <ReclaimTaskOverview reclaim={currentReclaim} notes={entities?.notes} />
                ) : (
                    <p className="text-gray-500 italic">
                        No reclaims found for the current filter.
                    </p>
                )}
            </MaxWidth>
        </Page>
    )
}

export default ReclaimTaskPage
