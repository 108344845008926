import Card from 'components/Card'
import MatchFilter from 'components/matches/MatchFilter'
import MatchList from 'components/matches/MatchList'
import NotesFilter from 'components/notes/NotesFilter'
import NotesList from 'components/notes/NotesList'
import ReclaimDetails from 'components/reclaims/ReclaimDetails'
import ReclaimsList from 'components/reclaims/ReclaimsList'
import ReclaimTaskDetails from 'components/reclaims/ReclaimTaskDetails'
import AddMatchManuallyButton from 'components/requests/AddMatchManuallyButton'
import RequestCard from 'components/requests/RequestCard'
import CategoryIcon from 'components/services/CategoryIcon'
import { useProvider } from 'hooks/providerHooks'
import React, { useEffect } from 'react'
import { Divider, LoadingDots } from '@advanza/ui'
import { useRequest } from 'hooks/requestHooks'
import { useService } from 'hooks/serviceHooks'
import LogsList from 'components/logs/LogsList'
import { changeNoteFilter } from 'actions/notes'
import { useAppDispatch } from 'hooks/hooks'

interface ReclaimTaskDetailProps {
    reclaim: Record<string, any>
    notes: Record<string, any>
}

const ReclaimTaskOverview: React.FC<ReclaimTaskDetailProps> = ({ reclaim, notes }) => {
    const { request, isLoading: isRequestLoading } = useRequest(reclaim.requestId)
    const { service, isLoading: isServiceLoading } = useService(reclaim.serviceId)
    const providerId = reclaim.providerId
    const isLoading = isRequestLoading || isServiceLoading
    const { provider } = useProvider(providerId)
    const filterIdAssociations = `p_${providerId}`
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(
            changeNoteFilter(filterIdAssociations, {
                didInvalidate: true,
                page: 0,
                providerIds: [providerId],
            })
        )
    }, [providerId])

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <ReclaimDetails reclaim={reclaim} />
                    <Divider m />

                    {isLoading ? (
                        <LoadingDots />
                    ) : request?.request_id && service?.service_id ? (
                        <>
                            <Card
                                header={<span>{service.name} Request</span>}
                                icon={<CategoryIcon color="blue" id={service?.category_id} />}>
                                <RequestCard requestId={request.request_id} />
                            </Card>
                            <Divider m />
                            <Card header="Matches (Request)" icon="compare_arrows">
                                <MatchFilter
                                    filterId={filterIdAssociations}
                                    compact
                                    hidePagination
                                    defaultOptions={{ requestIds: [request.request_id] }}
                                />
                                <MatchList
                                    showCols={[
                                        'id',
                                        'created',
                                        'name',
                                        'firstChoice',
                                        'from_dashboard',
                                        'charge',
                                        'confirm',
                                        'stripes',
                                        'nrMsgs',
                                        'partialMatch',
                                        'reclaim',
                                    ]}
                                    defaultOptions={{ pageSize: 10 }}
                                    filterId={filterIdAssociations}
                                />
                                <Divider />
                                <AddMatchManuallyButton
                                    serviceId={service.service_id}
                                    requestId={request.request_id}
                                    filterId={filterIdAssociations}
                                />
                            </Card>
                        </>
                    ) : (
                        <p className="text-sm text-gray-500 italic">
                            No request or service data found.
                        </p>
                    )}
                    <Divider m />
                    {provider ? (
                        <Card header="Matches (Provider)" icon="compare_arrows">
                            {provider.notes && (
                                <MatchFilter
                                    filterId={filterIdAssociations}
                                    compact
                                    hidePagination
                                    defaultOptions={{ providerIds: [providerId], pageSize: 10 }}
                                />
                            )}
                            <MatchList
                                filterId={filterIdAssociations}
                                showCols={[
                                    'charge',
                                    'firstChoice',
                                    'nrMsgs',
                                    'created',
                                    'userName',
                                    'stripes',
                                    'reclaim',
                                    'partialMatch',
                                ]}
                                hideExport={true}
                            />
                        </Card>
                    ) : (
                        <LoadingDots />
                    )}
                    <Divider m />
                </div>

                <div>
                    <Card header="Reclaim Info" icon="help_outline">
                        <Divider sm />
                        <ReclaimTaskDetails
                            reclaim={reclaim}
                            providerId={providerId}
                            notes={notes}
                        />
                    </Card>
                    <Divider m />
                    <Card header="Provider Reclaims" icon="mood_bad">
                        <ReclaimsList
                            filterId="provider_reclaims"
                            defaultOptions={{ providerId: providerId, pageSize: 10 }}
                            showCols={[
                                'reclaim_id',
                                'status',
                                'created',
                                'phoneNumber',
                                'leadName',
                                'action',
                                'notes',
                            ]}
                            showFilters={['leadType', 'status', 'search']}
                            hideExport={true}
                            compact
                        />
                    </Card>
                    <Divider m />
                    <Card header="Notes" icon="speaker_notes">
                        <NotesFilter
                            filterId={filterIdAssociations}
                            defaultOptions={{ providerIds: [providerId], pageSize: 10 }}
                            newEntityOptions={{ type: 'serviceProviders', foreign_key: providerId }}
                        />
                        <NotesList filterId={filterIdAssociations} />
                    </Card>
                    <Divider m />
                    <Card header="Log List" icon="edit_note">
                        <LogsList
                            filterId={'reclaimLog'}
                            options={{
                                providerIds: [providerId],
                                pageSize: 10,
                                page: 1,
                                logTypes: [
                                    'filters',
                                    'geo',
                                    'leadSettingsLimits',
                                    'week_limit_reached',
                                    'clear_week_limit',
                                ],
                            }}
                        />
                    </Card>
                    <Divider m />
                </div>
            </div>
        </div>
    )
}

export default ReclaimTaskOverview
